import React from "react"
import SEO from "../components/seo"
import "../bootstrap.min.css"

import { H1, H2, P } from "../components/text"
import Section from "../components/section"
import Footer from "../components/footer"

import imageVins from "../images/vins.jpg"
import imagePiucinque from "../images/piucinque.jpg"
import imageLimoncello from "../images/limoncello.jpg"

import Header from "../components/header"
import Hero from "../components/hero"

const Page = () =>
  <div>
    <SEO title="Home" />
    <Header/>

    <Hero title="Vins & Spiritueux" subtitle="Venez découvrir notre vaste gamme" image={imageVins}/>

    <Section className="container mb-5" style={{marginTop: "60px", marginBottom: "60px"}}>
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <H2 className="text-center">Nos vins</H2>
          <P>Depuis 50 ans, Raineri est en étroite collaboration avec plusieurs producteurs. Voici une liste non exhaustive de nos vins classé par région.</P>
          <P>Sachez que notre gérant se trouve à votre disposition pour vous guider dans votre sélection et vous assurer d’emporter un vin de qualité quelque soit votre budget.</P>
        </div>
      </div>
    </Section>

    <Section className="container text-center mb-5" style={{fontSize: "20px", color: "#666", lineHeight: "40px"}}>
      <div className="row">
        <div className="col-md-4">
          <strong>SICILIA</strong> <br/>
          TASCA D’ALMERITA <br/>
          SALLIER DE LA TOUR  <br/>
          <strong>EMIGLIA-ROMAGNA</strong> <br/>
          CHIARLI <br/>
          <strong>PIEMONTE</strong> <br/>
          CASCINA CHICCO <br/>
          VITE COLTE – TERRE DA VINO <br/>
          TENUTA NEIRANO <br/>
          <strong>SARDEGNA</strong>  <br/>
          SURRAU   <br/>
          SELLA&MOSCA <br/>
          <strong>TRENTO</strong> <br/>
          FERRARI TENUTE LUNELLI <br/>
          AZIENDA AGRICOLA PRAVIS <br/>
          <strong>FRIULI</strong> <br/>
          LIVON  <br/>
          VILLA CHIOPRIS <br/>
          LA DELIZIA
        </div>
        <div className="col-md-4">
          <strong>VENETO</strong> <br/>
          MONT’ALBANO <br/>
          CASA VINICOLA SARTORI  <br/>
          AZIENDA AGRICOLA MARCATO  <br/>
          GIANNI TESSARI <br/>
          ZARDETTO  <br/>
          AZIENDA AGRICOLA CASE PAOLIN   <br/>
          <strong>TOSCANA</strong><br/>
          CASA VINICOLA LUIGI CECCHI & FIGLI   <br/>
          MONTE GUELFO <br/>
          VILLA CERNA<br/>
          VAL DELLE ROSE<br/>
          VILLA ROSA  <br/>
          CASTELLO DI MONTAUTO<br/>
          TENUTE DEL CERRO - LA PODERINA <br/>
          TENUTE LUNELLI - PODERNOVO  <br/>
          BORGO SALCETINO <br/>
        </div>
        <div className="col-md-4">
          <strong>UMBRIA</strong>  <br/>
          TENUTA ALZATURA <br/>
          TENUTE LUNELLI - CASTELBUONO <br/>
          TENUTE DEL CERRO - COLPETRONE <br/>
          <strong>LAZIO</strong><br/>
          AZIENDA AGRICOLA L’OLIVELLA <br/>
          CANTINE SAN MARCO <br/>
          <strong>MARCHE</strong><br/>
          TERRE MONTE SCHIAVO  <br/>
          TENUTE PIERALISI  <br/>
          <strong>ABRUZZO</strong><br/>
          VILLA BIZZARRI  <br/>
          CERULLI SPINOZI <br/>
          <strong>CAMPANIA</strong><br/>
          MASTROBERARDINO  <br/>
          VILLA MATILDE  <br/>
          <strong>PUGLIA</strong> <br/>
          TENUTE RUBINO
        </div>
      </div>
    </Section>

    <Section className="container text-center mb-5">
      <div className="row">
        <div className="col-md-8 offset-md-2 mb-3">
          <H2>Spiritueux</H2>
          <P>Les alcools classiques d’Italie font aussi partie de nos rayons : Limoncello, Amaretto, Grappa, Averna… Venez découvrir notre nouveau Gin Italien de qualité!</P>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <img src={imageLimoncello} className="img-fluid rounded" style={{minHeight: "400px"}}/>
        </div>
        <div className="col-md-6">
          <img src={imagePiucinque} className="img-fluid rounded" style={{minHeight: "400px"}}/>
        </div>
      </div>
    </Section>

    <Footer/>
  </div>

export default Page
